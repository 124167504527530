<template>
  <v-container>
    <v-card>
      <v-container>
        <v-row>
          <v-col cols="12" class="text-center">
            <p class="title">Esta página no existe</p>
          </v-col>
          <v-col cols="12" class="text-center">
            <p class="text">
              Regresar a la página de <router-link to="/">inicio</router-link>
            </p>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<style scoped>
.title {
  font-family: "Aboreto";
  font-size: 40px;
  font-weight: bold;
  color: #344966;
}
.text {
  font-family: "Montserrat";
  color: #172b3b;
}
</style>
