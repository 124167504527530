import { createRouter, createWebHistory } from "vue-router";

import HomePage from "./pages/HomePage.vue";
import ContactPage from "./pages/ContactPage.vue";
import EventsPage from "./pages/EventsPage.vue";
import CamposBlancosPage from "./pages/CamposBlancosPage.vue";
import NotFoundPage from "./pages/NotFoundPage.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: HomePage,
      meta: {
        title: "Inicio",
      },
    },
    { path: "/inicio", name: "Inicio", redirect: "/" },
    {
      path: "/contacto",
      name: "Contacto",
      component: ContactPage,
      meta: {
        title: "Contacto",
      },
    },
    {
      path: "/eventos",
      name: "Eventos",
      component: EventsPage,
      meta: {
        title: "Eventos",
      },
    },
    {
      path: "/campos-blancos",
      name: "CamposBlancos",
      component: CamposBlancosPage,
      meta: {
        title: "Campos Blancos",
      },
    },
    {
      path: "/:notFound(.*)",
      component: NotFoundPage,
      name: "NotFound",
      meta: { title: "404" },
    },
  ],
});

export default router;
