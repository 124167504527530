<template>
  <v-col cols="12" class="py-0">
    <h2 class="header">Contacto</h2>
  </v-col>
  <v-col cols="12" class="pb-1">
    <a href="tel:(616) 329-2108" class="text">(616) 329-2108</a>
  </v-col>
  <v-col cols="12" class="py-0">
    <a href="mailto:mauro1130mtz@gmail.com" class="text">mauro1130mtz@gmail.com</a>
  </v-col>
  <v-col cols="12" class="py-0">
    <v-btn
      v-for="icon in icons"
      :key="icon.type"
      :icon="icon.type"
      variant="text"
      :href="icon.href"
      target="_blank"
    ></v-btn>
  </v-col>
  <v-col cols="12" class="pb-0">
    <h2 class="header">Dirección</h2>
  </v-col>
  <v-col cols="12">
    <p class="text">2501 Collins St <br> Blue Island, IL 60406</p>
  </v-col>
  <v-col cols="12">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5960.576971366352!2d-87.68458712314846!3d41.671112666956475!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e254968b6aa87%3A0x243fcee10c99daad!2sIglesia%20Cristiana%20Pentecostes%20Resplandor%20de%20Gloria!5e0!3m2!1sen!2sus!4v1682381337761!5m2!1sen!2sus"
      width="100%"
      height="245"
      style="border: 0"
      allowfullscreen="true"
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
    ></iframe>
  </v-col>
</template>

<script>
export default {
  setup() {
    const icons = [
      {
        type: "mdi-facebook",
        href: "https://www.facebook.com/IglesiaCristianaPentecostesBlueIsland/",
      },
      {
        type: "mdi-youtube",
        href: "https://www.youtube.com/@icp-resplandordegloriablue5941",
      },
    ];

    return { icons };
  },
};
</script>

<style scoped>
.header {
  font-family: "Sacramento";
  font-weight: normal;
  font-size: 30px;
  color: #344966;
}

.text {
  font-family: "Gotu";
  font-size: 16px;
  font-style: italic;
  color: #27292B;
}
</style>
