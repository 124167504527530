<template>
  <div>
    <v-row
      v-for="(event, index) in events"
      :key="index"
      justify="center"
      align="center"
      align-content="center"
    >
      <v-col :cols="smAndDown ? 12 : 8" align-self="center" class="px-0">
        <v-card min-width="343" elevation="5">
          <v-img
            v-if="event.image"
            eager
            :src="
              smAndDown
                ? require(`../../assets/${event.smImage}`)
                : require(`../../assets/${event.image}`)
            "
            :height="xlAndUp ? '500px' : '275px'"
            :alt="event.alt"
            cover
          ></v-img>
          <v-card-actions v-if="event.text || event.buttonText">
            <v-spacer></v-spacer>
            <v-btn
              :append-icon="event.show ? 'mdi-chevron-up' : 'mdi-chevron-down'"
              @click="event.show = !event.show"
              :disabled="!event.text"
              >{{
                event.buttonText ? event.buttonText : "Mas Información"
              }}</v-btn
            >
          </v-card-actions>
          <v-expand-transition>
            <div v-show="event.show">
              <v-divider></v-divider>

              <v-container fluid>
                <p v-html="event.text" class="text"></p>
                <v-spacer></v-spacer>
                <v-btn
                  class="mt-6"
                  color="#344966"
                  prepend-icon="mdi-map-marker"
                  :href="
                    platform.ios ? event.appleMapsLink : event.googleMapsLink
                  "
                  target="_blank"
                  variant="tonal"
                  >Mapa</v-btn
                >
              </v-container>
            </div>
          </v-expand-transition>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { useDisplay } from "vuetify";

export default {
  props: {
    events: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const { smAndDown, xlAndUp, platform } = useDisplay();

    return {
      smAndDown,
      xlAndUp,
      platform,
    };
  },
};
</script>
