<template>
  <v-app-bar density="prominent" :height="appBarHeight">
    <v-container fill-height fluid>
      <v-row no-gutters align="center" align-content="center" justify="center">
        <v-col cols="12" align-self="center">
          <h1 :style="titleStyle">
            Resplandor de Gloria <br />
            Blue Island
          </h1>
        </v-col>
      </v-row>
    </v-container>

    <template #prepend>
      <v-img
        :src="require('../../assets/logo.png')"
        class="customPic"
        :style="logoStyle"
        :width="smAndDown ? '45' : '60'"
        @click="$router.push('/')"
      />
    </template>

    <template #append>
      <v-btn icon v-if="smAndDown">
        <v-menu>
          <template v-slot:activator="{ props }">
            <v-btn icon="mdi-menu" v-bind="props"></v-btn>
          </template>

          <v-list nav>
            <v-list-item
              v-for="(link, i) in links"
              :key="i"
              :value="i"
              :to="link.to"
              :color="activeLink && activeLink.to === link.to ? '#A9B52C' : ''"
            >
              <template #prepend>
                <v-icon>{{ link.icon }}</v-icon>
              </template>
              <v-list-item-title>{{ link.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-btn>

      <div class="navbar" v-else>
        <router-link
          v-for="link in links"
          :key="link.text"
          :to="link.to"
          :style="
            activeLink && activeLink.to === link.to
              ? { 'text-decoration': 'underline' }
              : {}
          "
          >{{ link.text }}</router-link
        >
      </div>
    </template>
  </v-app-bar>
</template>

<script>
import { useDisplay } from "vuetify";
import { computed, reactive } from "vue";
import { useRoute } from "vue-router";

export default {
  setup() {
    const links = reactive([
      { text: "Inicio", to: "/", icon: "mdi-home" },
      { text: "Campos Blancos", to: "/campos-blancos", icon: "mdi-book-cross" },
      { text: "Eventos", to: "/eventos", icon: "mdi-calendar-multiselect" },
      { text: "Contacto", to: "/contacto", icon: "mdi-phone" },
    ]);

    const { mobile, smAndDown, width } = useDisplay();

    const titleStyle = computed(() => {
      let paddingTop;
      if (width.value <= 960) {
        paddingTop = "4px";
      } else {
        paddingTop = "0px";
      }

      return {
        "font-family": "Aboreto",
        "font-weight": "normal",
        "font-size": smAndDown.value ? "16px" : "25px",
        color: "#172B3B",
        "padding-top": paddingTop,
      };
    });

    let logoStyle = computed(() => {
      let marginLeft;
      let marginRight;
      if (smAndDown.value) {
        marginLeft = "10px";
        marginRight = "10px";
      } else {
        marginLeft = "20px";
        marginRight = "25px";
      }

      return {
        "margin-left": marginLeft,
        "margin-right": marginRight,
      };
    });


    let appBarHeight = computed(() => {
      if (width.value <= 360) {
        return "50";
      } else if (width.value < 960) {
        return "45";
      } else {
        return "54";
      }
    })

    const route = useRoute();
    const activeLink = computed(() => {
      return links.find((link) => link.to === route.path);
    });

    return {
      links,
      mobile,
      smAndDown,
      width,
      titleStyle,
      logoStyle,
      appBarHeight,
      activeLink,
    };
  },
};
</script>

<style scoped>
.navbar {
  display: flex;
  /* Use flexbox to align the links horizontally */
  justify-content: space-between;
  /* Add equal spacing between the links */
  align-items: center;
  /* Center align the links vertically */
}

.navbar a {
  color: #172b3b;
  /* Set link color to Material Design Blue */
  text-decoration: none;
  /* Remove default underline */
  transition: color 0.2s ease-in-out;
  /* Add a smooth transition when the color changes */
  margin-right: 35px;
  /* Add margin to the right of each link to create spacing */
  cursor: pointer;
  /* Add pointer cursor on hover */

  font-family: "Gotu";
  font-size: 16px;
}

.navbar a:hover {
  color: #889da2;
  /* Change link color on hover */
  text-decoration: underline;
  /* Add underline on hover */
}

.customPic:hover {
  cursor: pointer;
}
</style>
