<template>
  <v-container class="contactPage">
    <meta name="description" content="Llene este formulario y te contestaremos lo antes posible." />
    <v-row class="text-center">
      <v-col :cols="smAndDown ? 12 : 6" class="pb-0">
        <h1 class="title" :style="titleStyle">Contáctenos</h1>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col :cols="smAndDown ? 12 : 6">
        <p class="subtitle px-8">
          ¿Tiene alguna pregunta, necesita información o necesita oración? Llene
          este formulario y te contestaremos lo antes posible.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="mdAndUp ? 6 : 12">
        <ContactForm />
      </v-col>
      <v-col :cols="mdAndUp ? 5 : 12" :offset="mdAndUp ? 1 : null">
        <ContactInfo />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useDisplay } from "vuetify";
import { computed } from "vue";

import ContactForm from "../components/contact/ContactForm.vue";
import ContactInfo from "../components/contact/ContactInfo.vue";
export default {
  components: {
    ContactForm,
    ContactInfo,
  },
  setup() {
    const { mobile, smAndDown, mdAndUp } = useDisplay();

    let titleStyle = computed(() => {
      return {
        fontSize: mobile.value ? "30px" : "40px",
      };
    });

    window.requestAnimationFrame(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    });

    return {
      mobile,
      smAndDown,
      mdAndUp,
      titleStyle,
    };
  },
};
</script>

<style scoped>
.title {
  font-family: "Aboreto";
  font-weight: bold;
  color: #344966;
}

.subtitle {
  font-family: "Gotu";
  font-size: 16px;
}

.contactPage {
  color: #27292b;
}
</style>
