<template>
  <v-form @submit.prevent>
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="name.first"
          :error-messages="name.errorMessage"
          label="Nombre *"
          variant="filled"
          @update:model-value="resetValidation"
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="name.last"
          label="Apellido"
          variant="filled"
          @update:model-value="resetValidation"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="phone.value"
          :error-messages="phone.errorMessage"
          label="Número de teléfono"
          variant="filled"
          type="tel"
          :persistent-hint="true"
          id="phone"
          hint="Por favor ingrese su correo electrónico o teléfono"
          @update:model-value="resetValidation"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          v-model="email.value"
          :error-messages="email.errorMessage"
          label="Correo electrónico"
          variant="filled"
          type="email"
          :persistent-hint="true"
          hint="Por favor ingrese su correo electrónico o teléfono"
          @update:model-value="resetValidation"
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-textarea
          v-model="message.value"
          :error-messages="message.errorMessage"
          @update:model-value="resetValidation"
          label="Mensaje *"
          variant="filled"
        ></v-textarea>
      </v-col>
      <v-col cols="12">
        <v-btn
          :loading="loading"
          :disabled="loading"
          color="#344966"
          type="submit"
          @click="submit"
          block
          variant="tonal"
          >Enviar</v-btn
        >
      </v-col>
      <v-col cols="12">
        <p class="info">
          This site is protected by reCAPTCHA and the Google
          <a href="https://policies.google.com/privacy">Privacy Policy</a> and
          <a href="https://policies.google.com/terms">Terms of Service</a>
          apply.
        </p>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
      location="top"
    >
      {{ snackbar.text }}
      <template v-slot:actions>
        <button @click="snackbar.show = false">OK</button>
      </template>
    </v-snackbar>
  </v-form>
</template>

<script>
import axios from "axios";
import { ref, reactive, onMounted } from "vue";
import { useReCaptcha } from "vue-recaptcha-v3";
import {
  AsYouType,
  isValidPhoneNumber,
  validatePhoneNumberLength,
} from "libphonenumber-js";
var validator = require("email-validator");
export default {
  setup() {
    const name = reactive({
      first: "",
      last: "",
      errorMessage: "",
    });
    const email = reactive({
      value: "",
      errorMessage: "",
    });
    const message = reactive({
      value: "",
      errorMessage: "",
    });
    const phone = reactive({
      value: "",
      errorMessage: "",
    });

    const loading = ref(false);

    const snackbar = reactive({
      show: false,
      text: "Su mensaje ha sido enviado",
      color: "#82A7A6",
      timeout: 7000,
    });

    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

    const submit = async () => {
      loading.value = true;
      const valid = validateForm();
      if (!valid) {
        loading.value = false;
        return;
      }

      await recaptchaLoaded();
      const token = await executeRecaptcha("login");

      const contactForm = {
        name: name.first + " " + name.last,
        email: email.value,
        message: message.value,
        phone: phone.value,
      };

      const response = await axios.post(
        "https://rdgblueisland-backend.herokuapp.com/verify",
        {
          token,
          contactForm,
        }
      );

      if (!response.data.success) {
        setTimeout(() => {
          snackbar.show = true;
          snackbar.text = "Ha ocurrido un error. Por favor intente de nuevo";
          snackbar.color = "#EF6F6C";
          loading.value = false;
        }, 5000);
        return;
      } else {
        snackbar.show = true;
        snackbar.text = "Su mensaje ha sido enviado";
        snackbar.color = "#82A7A6";
        loading.value = false;
      }

      resetForm();
    };

    const validateForm = () => {
      let valid = true;

      if (name.first)
        name.first = name.first.replace(/\s+/g, "").replace(/[^a-zA-Z ]/g, "");
      if (name.last)
        name.last = name.last.replace(/\s+/g, "").replace(/[^a-zA-Z ]/g, "");
      if (email.value) email.value = email.value.replace(/\s+/g, "");

      if (message.value)
        message.value = message.value.trim().replace(/( |\n)+/g, (match) => {
          return match.includes(" ") ? " " : "\n";
        });

      if (!name.first) {
        name.errorMessage = "Por favor ingrese su nombre";
        valid = false;
      }

      if (!email.value && !phone.value) {
        email.errorMessage =
          "Por favor ingrese su correo electrónico o teléfono";
        phone.errorMessage =
          "Por favor ingrese su correo electrónico o teléfono";
        valid = false;
      }

      if (email.value && !validator.validate(email.value)) {
        email.errorMessage = "El correo electrónico debe ser válido";
        valid = false;
      }

      if (phone.value && !isValidPhoneNumber(phone.value, "US")) {
        phone.errorMessage = "El número de teléfono debe ser válido";
        valid = false;
      }

      if (!message.value) {
        message.errorMessage = "Por favor ingrese un mensaje";
        valid = false;
      }

      return valid;
    };

    const resetForm = () => {
      name.first = "";
      name.last = "";
      email.value = "";
      message.value = "";
      phone.value = "";
    };

    const resetValidation = () => {
      name.errorMessage = "";
      email.errorMessage = "";
      phone.errorMessage = "";
      message.errorMessage = "";
    };

    const updatePhoneNumber = (value) => {
      if (validatePhoneNumberLength(value, "US") === "NOT_A_NUMBER") {
        phone.value = value.substring(0, value.length - 1);
        return;
      }
      if (
        validatePhoneNumberLength(value, "US") === "TOO_SHORT" ||
        !validatePhoneNumberLength(value, "US") ||
        isValidPhoneNumber(value, "US")
      ) {
        phone.value = new AsYouType("US").input(value);
      } else if (validatePhoneNumberLength(value, "US") === "TOO_LONG") {
        phone.value = value.substring(0, value.length - 1);
      }
    };

    // on mounted hook, add event listener to the input element with id 'phone'
    onMounted(() => {
      const phoneInput = document.getElementById("phone");
      phoneInput.addEventListener("input", (e) => {
        if (e.inputType === "deleteContentBackward") return;
        updatePhoneNumber(e.target.value);
      });
    });

    return {
      name,
      email,
      message,
      phone,
      submit,
      resetValidation,
      validateForm,
      loading,
      snackbar,
      updatePhoneNumber,
    };
  },
};
</script>

<style scoped>
::v-deep .v-btn__content {
  font-family: "Aboreto";
  font-size: 20px;
}

.info {
  font-size: 12px;
  color: #344966;
  text-align: center;
  margin-top: 10px;
  opacity: 0.5;
}
</style>
