<template>
  <v-footer class="footer" theme="dark">
    <v-row justify="center" no-gutters>
      <v-btn v-for="icon in icons" :key="icon.type" class="mx-4" :icon="icon.type" variant="text" :href="icon.href"
        target="_blank"></v-btn>

      <v-col class="text-center mt-4" cols="12">2501 Collins St <br> Blue Island, IL 60406</v-col>
      <v-col class="text-center mt-4" cols="12"><a href="tel:(616) 329-2108">(616) 329-2108</a></v-col>
      <v-col class="text-center mt-4" cols="12">
        Visite la página oficial de
        <a class="link" href="https://www.resplandordegloria.org/" target="_blank">Resplandor De Gloria</a>
      </v-col>
      <v-col class="text-center mt-4" cols="12">{{ new Date().getFullYear() }} — Resplandor De Gloria | Blue
        Island</v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  setup() {
    const icons = [
      {
        type: "mdi-facebook",
        href: "https://www.facebook.com/IglesiaCristianaPentecostesBlueIsland/",
      },
      {
        type: "mdi-youtube",
        href: "https://www.youtube.com/@icp-resplandordegloriablue5941"
      }
    ];

    return { icons };
  },
};
</script>

<style scoped>
.footer {
  bottom: 0;
  width: 100%;

  background-color: #889DA2;

  font-family: "Gotu";
  font-size: 16px;
  max-height: 300px;
  height: 300px;
}

a {
  color: white;
}
</style>
