<template>
  <div>
    <meta name="description" content="Nuestra iglesia esta atendiendo obras en la ciudade de Kenosha, Wisconsin." />
    <v-container fluid style="border-bottom: 1px solid #344966">
      <v-row>
        <v-col class="text-center mt-8">
          <h1 class="title" :style="titleFontStyle">Campos Blancos</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center mb-8 bibleText">
          <p>
            "Te encarezco delante de Dios y del Señor Jesucristo, que juzgará a los vivos y a los muertos en su manifestación y en su reino, que
            prediques la palabra; que instes a tiempo y fuera de tiempo; redarguye, reprende, exhorta con toda paciencia y doctrina. " - 2 Timoteo
            4:1-2
          </p>
        </v-col>
      </v-row>
    </v-container>
    <v-row no-gutters>
      <v-col :cols="numberOfColumns">
        <v-carousel hide-delimiters show-arrows="hover" continuous cycle :height="cardHeight">
          <v-carousel-item
            v-for="(img, index) in kenoshaImages"
            :key="index"
            cover
            :src="require(`../assets/${img.src}.jpeg`)"
            :alt="img.alt"></v-carousel-item>
        </v-carousel>
      </v-col>
      <v-col :cols="numberOfColumns">
        <v-card :height="cardHeight" variant="tonal" :color="cardColor">
          <v-container class="cardContainer">
            <v-row class="innerRow">
              <v-col class="text-center text" cols="12" align-self="center">
                <h2>Kenosha, WI</h2>
              </v-col>
            </v-row>
            <v-row class="innerRow">
              <v-col class="text-center text" cols="12" align-self="center">
                <b>Reuniones:</b> <br />
                Sábado y Domingo - 6:00pm
              </v-col>
            </v-row>
            <v-row class="innerRow">
              <v-col class="text-center text" cols="12" align-self="center">
                <b>Dirección:</b> <br />
                7002 34th Ave, Kenosha WI 53142
              </v-col>
            </v-row>
            <v-row class="innerRow">
              <v-col class="text-center text" cols="12" align-self="center">
                <v-btn color="#344966" to="/contacto" variant="tonal">Contacto</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { useDisplay } from 'vuetify';
import { computed, ref, reactive } from 'vue';
export default {
  setup() {
    const { smAndDown, xlAndUp } = useDisplay();

    const kenoshaImages = reactive([
      {
        src: 'kenosha1',
        alt: 'La iglesia de Resplandor de Gloria en Kenosha, WI adorando a Dios',
      },
      {
        src: 'kenosha2',
        alt: 'La iglesia de Resplandor de Gloria en Kenosha, WI escuchando la palabra de Dios',
      },
      {
        src: 'kenosha3',
        alt: 'La iglesia de Resplandor de Gloria en Kenosha, WI escuchando la palabra de Dios',
      },
      {
        src: 'kenosha4',
        alt: 'La iglesia de Resplandor de Gloria en Kenosha, WI adorando a Dios',
      },
    ]);

    const numberOfColumns = computed(() => {
      if (smAndDown.value) return 12;
      else return 6;
    });

    const titleFontStyle = computed(() => {
      if (smAndDown.value) return 'font-size: 40px';
      else return 'font-size: 50px';
    });

    const cardColor = ref('#f4f6f6');

    const cardHeight = computed(() => {
      if (smAndDown.value) return 400;
      else return 600;
    });

    window.requestAnimationFrame(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    });

    return {
      smAndDown,
      xlAndUp,
      numberOfColumns,
      titleFontStyle,
      cardColor,
      kenoshaImages,
      cardHeight,
    };
  },
};
</script>

<style scoped>
.title {
  font-family: 'Sacramento';
  font-weight: normal;
  color: #344966;
}

.text {
  font-family: 'Gotu';
  color: #344966;
}

/* fill height and align content center */
.cardContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.innerRow {
  max-height: 100px;
}

.bibleText {
  font-family: 'Gotu';
  font-style: italic;
  font-size: 16px;
  color: #344966;
}
</style>
