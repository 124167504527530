<template>
  <v-container>
    <meta name="description" content="Eventos de la obra Resplandor De Gloria para el año 2024" />
    <v-row align="center" justify="center">
      <v-col cols="12" class="text-center">
        <h1 class="title" :style="titleStyle">Eventos | 2024</h1>
      </v-col>
    </v-row>

    <EventCards :events="events" />
  </v-container>
</template>

<script>
import { ref, computed } from 'vue';
import { useDisplay } from 'vuetify';
import EventCards from '@/components/events/EventCards.vue';

export default {
  components: {
    EventCards,
  },
  setup() {
    let tab = ref('upcoming');
    let show = ref(false);

    const events = ref([
      // {
      //   image: "1.png",
      //   alt: "Retiro Confraternidad Por Sectores Zona 1",
      //   smImage: "1sm.png",
      //   show: true,
      //   date: "5/13/2023",
      //   text: `<strong>Retiro Confraternidad Por Sectores Zona 1</strong> <br/><br/>
      //   <b>Fecha:</b><br/> sábado 13 de mayo <br/><br/>
      //   <b>Dirección:</b> <br/> Prayer House Assembly of God <br/>
      //   1525 24th Ave, Kenosha WI 53140 <br/><br/>
      //   <b>Horario:</b><br/> 9:00 AM`,
      //   googleMapsLink:
      //     "https://goo.gl/maps/AoFbHpY8hMF9xdwMA?coh=178571&entry=tt",
      //   appleMapsLink:
      //     "https://maps.apple.com/place?q=Prayer%20House%20Assembly%20of%20God&ll=42.6306907%2C-87.8371435&auid=14957388348585161208&lsp=9902&address=1525%2024th%20Ave%2C%20Kenosha%2C%20WI%20%2053140%2C%20United%20States",
      // },
      // {
      //   image: "2.png",
      //   alt: "Convención Nacional 2023",
      //   smImage: "2sm.png",
      //   show: true,
      //   date: "06/23/23",
      //   text: `<strong>Convención Nacional</strong> <br/><br/>
      //   <b>Fecha:</b><br/> miercoles 21 de junio a viernes 23 de junio <br/><br/>
      //   <b>Dirección:</b> <br/> St. Mark Missionary Baptist Church <br/>
      //   14618 Lincoln Ave, Harvey IL, 60426 <br/><br/>
      //   <b>Horario:</b>
      //   <br/> miercoles: 7:00 PM
      //   <br/> jueves y viernes: 9:00 AM y 6:00 PM`,
      //   googleMapsLink:
      //     "https://goo.gl/maps/688uH5muLeivHQYd7",
      //   appleMapsLink:
      //     "https://maps.apple.com/place?q=St.%20Mark%20Missionary%20Baptist%20Church&ll=41.623284%2C-87.663632&auid=7835565691143482277&lsp=9902&address=14618%20Lincoln%20Ave%2C%20Harvey%2C%20IL%20%2060426%2C%20United%20States",
      // },
      // {
      //   image: "3.png",
      //   alt: "Confraternidad de Damas 2023",
      //   smImage: "3sm.png",
      //   show: true,
      //   date: "08/05/2023",
      //   text: `<strong>Confraternidad de Damas</strong> <br/><br/>
      //   <b>Fecha:</b><br/> sábado 5 de agosto <br/><br/>
      //   <b>Dirección:</b> <br/> Iglesia Cristiana Resplandor de Gloria Cincinnati, OH <br/>
      //   1848 Summit Rd<br/> Cincinnati, OH 45237<br/><br/>
      //   <b>Horario:</b><br/> 11:00 AM`,
      //   googleMapsLink:
      //     "https://goo.gl/maps/yMD1ipnKGh2vhYoU7",
      //   appleMapsLink:
      //     "https://maps.apple.com/place?q=1848%20Summit%20Rd&ll=39.2021968%2C-84.4542064&address=1848%20Summit%20Rd%2C%20Cincinnati%2C%20OH%20%2045237%2C%20United%20States",
      // },
      // {
      //   image: "4.png",
      //   alt: "Confraternidad de Niños y Adolescentes 2023",
      //   smImage: "4sm.png",
      //   show: true,
      //   date: "09/09/2023",
      //   text: `<strong>Confraternidad de Niños y Adolescentes</strong> <br/><br/>
      //   <b>Fecha:</b><br/> sábado 9 de septiembre <br/><br/>
      //   <b>Dirección:</b> <br/>
      //   9035 E 21st St<br/> Indianapolis, IN<br/><br/>
      //   <b>Horario:</b><br/> 11:00 AM`,
      //   googleMapsLink:
      //     "https://goo.gl/maps/xjsTKiVhtyMqSKW97",
      //   appleMapsLink:
      //     "https://maps.apple.com/place?q=9035%20E%2021st%20St&ll=39.796286%2C-86.0080511&address=9035%20E%2021st%20St%2C%20Indianapolis%2C%20IN%20%2046229%2C%20United%20States",
      // },
      // {
      //   image: "5.png",
      //   alt: "Retiro De Pastores y Lideres 2023",
      //   smImage: "5sm.png",
      //   show: false,
      //   date: "10/07/2023",
      // },
      // {
      //   image: "6.png",
      //   alt: "Convención Nacional 2023",
      //   smImage: "6sm.png",
      //   show: true,
      //   date: "11/24/2023",
      //   text: `<strong>Convención Nacional</strong> <br/><br/>
      //   <b>Fecha:</b><br/> miercoles 22 de noviembre a viernes 24 de noviembre <br/><br/>
      //   <b>Dirección:</b> <br/> Hilton Houston North <br/>
      //   12400 Greenspoint Drive<br/>
      //   Houston TX 77060<br/><br/>
      //   <b>Horario:</b>
      //   <br/> miercoles: 7:00 PM
      //   <br/> jueves y viernes: 9:00 AM y 6:00 PM`,
      //   googleMapsLink:
      //     "https://maps.app.goo.gl/juskFgmsRbAWh3DH6",
      //   appleMapsLink:
      //     "https://maps.apple.com/place?q=12400%20Greenspoint%20Dr&ll=29.9468752%2C-95.4069818&address=12400%20Greenspoint%20Dr%2C%20Houston%2C%20TX%20%2077060%2C%20United%20States",
      // },
      // {
      //   image: "7.png",
      //   alt: "Confraternidad de Jóvenes 2023",
      //   smImage: "7sm.png",
      //   show: true,
      //   date: "12/16/2023",
      //   text: `<strong>Confraternidad de Jóvenes</strong> <br/><br/>
      //   <b>Fecha:</b><br/> sábado 12 de diciembre <br/><br/>
      //   <b>Dirección:</b> <br/> Iglesia Cristiana Resplandor de Gloria Cincinnati, OH <br/>
      //   1848 Summit Rd<br/> Cincinnati, OH 45237<br/><br/>
      //   <b>Horario:</b><br/> 11:00 AM`,
      //   googleMapsLink:
      //     "https://goo.gl/maps/yMD1ipnKGh2vhYoU7",
      //   appleMapsLink:
      //     "https://maps.apple.com/place?q=1848%20Summit%20Rd&ll=39.2021968%2C-84.4542064&address=1848%20Summit%20Rd%2C%20Cincinnati%2C%20OH%20%2045237%2C%20United%20States",
      // },
      {
        image: '03-01-24.png',
        alt: 'Retiro Nacional De Pastores y Lideres 2024',
        smImage: '03-01-24-sm.png',
        show: false,
        date: '03/01/24',
      },
      {
        image: '03-23-24.png',
        alt: 'Confraternidad 2024',
        smImage: '03-23-24-sm.png',
        show: true,
        date: '03/23/24',
        text: `<strong>Confraternidad</strong> <br/><br/>
        <b>Fecha:</b><br/> sábado 23 de marzo <br/><br/>
        <b>Dirección:</b> <br/> Iglesia Cristiana Resplandor de Gloria Cincinnati, OH <br/> 
        1848 Summit Rd<br/> Cincinnati, OH 45237<br/><br/> 
        <b>Horario:</b><br/> 11:00 AM`,
        googleMapsLink: 'https://goo.gl/maps/yMD1ipnKGh2vhYoU7',
        appleMapsLink:
          'https://maps.apple.com/place?q=1848%20Summit%20Rd&ll=39.2021968%2C-84.4542064&address=1848%20Summit%20Rd%2C%20Cincinnati%2C%20OH%20%2045237%2C%20United%20States',
      },
      {
        image: '04-20-24.png',
        alt: 'Confraternidad de Damas y Caballeros 2024',
        smImage: '04-20-24-sm.png',
        show: true,
        date: '04/20/24',
        text: `<strong>Confraternidad de Damas y Caballeros</strong> <br/><br/>
        <b>Fecha:</b><br/> sábado 20 de abril <br/><br/>
        <b>Dirección:</b> <br/> St. Mark Missionary Baptist Church <br/> 
        14618 Lincoln Ave<br/> Harvey IL, 60426 <br/><br/> 
        <b>Horario:</b><br/> 11:00 AM`,
        googleMapsLink: 'https://goo.gl/maps/688uH5muLeivHQYd7',
        appleMapsLink:
          'https://maps.apple.com/place?q=St.%20Mark%20Missionary%20Baptist%20Church&ll=41.623284%2C-87.663632&auid=7835565691143482277&lsp=9902&address=14618%20Lincoln%20Ave%2C%20Harvey%2C%20IL%20%2060426%2C%20United%20States',
      },
      {
        image: '05-11-24.png',
        alt: 'Confraternidad de Niños 2024',
        smImage: '05-11-24-sm.png',
        show: true,
        date: '05/11/24',
        text: `<strong>Confraternidad de Niños </strong> <br/><br/>
        <b>Fecha:</b><br/> sábado 11 de mayo <br/><br/>
        <b>Dirección:</b> <br/> 
        9035 E 21st St<br/> Indianapolis, IN<br/><br/>
        <b>Horario:</b><br/> 11:00 AM`,
        googleMapsLink: 'https://goo.gl/maps/xjsTKiVhtyMqSKW97',
        appleMapsLink:
          'https://maps.apple.com/place?q=9035%20E%2021st%20St&ll=39.796286%2C-86.0080511&address=9035%20E%2021st%20St%2C%20Indianapolis%2C%20IN%20%2046229%2C%20United%20States',
      },
      {
        image: '06-28-24.png',
        alt: 'Convención Nacional 2024',
        smImage: '06-28-24-sm.png',
        show: true,
        date: '06/28/24',
        text: `<strong>Convención Nacional</strong> <br/><br/>
        <b>Fecha:</b><br/> miercoles 26 de junio a viernes 28 de junio <br/><br/>
        <b>Dirección:</b> <br/> Galilee Baptist Church <br/>
        1216 N Westedge Ave<br/> Kalamazoo, MI 49007 <br/><br/>
        <b>Horario:</b>
        <br/> miercoles: 7:00 PM
        <br/> jueves y viernes: 9:00 AM y 6:00 PM`,
        googleMapsLink: 'https://maps.app.goo.gl/27GSEvTZjjxABuvo8',
        appleMapsLink:
          'https://maps.apple.com/place?q=The%20Galilee%20Baptist%20Church&ll=42.3026029%2C-85.5891609&auid=1007165846577602320&lsp=9902&address=1216%20N%20Westnedge%20Ave%2C%20Kalamazoo%2C%20MI%20%2049007%2C%20United%20States',
      },
      {
        image: '09-14-24.png',
        alt: 'Confraternidad de Jovenes 2024',
        smImage: '09-14-24-sm.png',
        show: false,
        date: '09/14/24',
      },
      {
        image: '10-05-24.png',
        alt: 'Retiro De Pastores y Lideres 2024',
        smImage: '10-05-24-sm.png',
        show: false,
        date: '10/5/24',
      },
      {
        image: '10-19-24.png',
        alt: 'Confraternidad por Sectores 2024',
        smImage: '10-19-24-sm.png',
        show: false,
        date: '10/19/24',
      },
      {
        image: '11-29-24.png',
        alt: 'Convención de Jovenes 2024',
        smImage: '11-29-24-sm.png',
        show: false,
        date: '11/29/24',
      },
      {
        image: '12-14-24.png',
        alt: 'Confraternidad 2024',
        smImage: '12-14-24-sm.png',
        show: true,
        date: '12/14/24',
        text: `<strong>Confraternidad</strong> <br/><br/>
        <b>Fecha:</b><br/> sábado 23 de marzo <br/><br/>
        <b>Dirección:</b> <br/> Iglesia Cristiana Resplandor de Gloria Cincinnati, OH <br/> 
        1848 Summit Rd<br/> Cincinnati, OH 45237<br/><br/> 
        <b>Horario:</b><br/> 11:00 AM`,
        googleMapsLink: 'https://goo.gl/maps/yMD1ipnKGh2vhYoU7',
        appleMapsLink:
          'https://maps.apple.com/place?q=1848%20Summit%20Rd&ll=39.2021968%2C-84.4542064&address=1848%20Summit%20Rd%2C%20Cincinnati%2C%20OH%20%2045237%2C%20United%20States',
      },
    ]);

    for (let i = 0; i < events.value.length; i++) {
      if (events.value[i].date) {
        let currentDate = new Date();
        let targetDate = new Date(events.value[i].date);

        currentDate.setHours(0, 0, 0, 0);
        targetDate.setHours(0, 0, 0, 0);

        if (targetDate < currentDate) {
          let event = events.value[i];
          event.show = false;
        }
      }
    }

    const { smAndDown, xlAndUp, mobile, platform } = useDisplay();

    let titleStyle = computed(() => {
      return {
        fontSize: mobile.value ? '30px' : '40px',
      };
    });

    window.requestAnimationFrame(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    });

    return {
      events,
      smAndDown,
      mobile,
      titleStyle,
      show,
      xlAndUp,
      platform,
      tab,
    };
  },
};
</script>

<style scoped>
.title {
  font-family: 'Gotu';
  font-weight: normal;
  font-size: 40px;
  color: #344966;
}

.header {
  font-family: 'Gotu';
  font-size: 25px;
  font-weight: bold;
  color: #344966;
}

.date {
  font-family: 'Gotu';
  font-size: 16px;
  font-weight: bold;
  color: #344966;
}

.location {
  font-family: 'Gotu';
  font-size: 16px;
  color: #344966;
}

.text {
  font-family: 'Montserrat';
  color: #172b3b;
}

::v-deep .v-btn__content {
  font-family: 'Aboreto';
  text-transform: none;
  font-size: 16px;
  color: #172b3b;
}

.v-window {
  overflow: unset !important;
}
</style>
