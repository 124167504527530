import { createApp } from 'vue';
import router from './router';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import VueGtag from 'vue-gtag';

loadFonts();

const app = createApp(App);

app.use(vuetify);
app.use(router);

app.use(
  VueGtag,
  {
    config: { id: 'G-EK5YS2L04P' },
  },
  router
);
app.use(VueReCaptcha, { siteKey: '6Lf54cslAAAAAF5QTGYKXiq5hMskt9qpYeqU4AW8' });

app.mount('#app');
